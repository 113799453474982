import React from "react";
import { css } from "@emotion/react";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./Header";

import "./layout.css";

export default function Layout({ children }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  return (
    <div
      css={css`
        margin: 0 auto;
        max-width: 960px;
      `}
    >
      <div
        css={css`
          margin: 0 1.5rem;
        `}
      >
        <Header title={data.site.siteMetadata.title} />
        <main>{children}</main>
      </div>
    </div>
  );
}
