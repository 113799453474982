import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const getMetadataTags = () => {
    const metaDescription = description ?? site.siteMetadata.description;
    const tags = [
      {
        name: `description`,
        content: metaDescription,
      },
    ];

    if (meta.length === 0) {
      tags.concat([
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]);
    }

    return tags;
  };

  const titleString = title ?? site.siteMetadata.title;
  const titleTemplate =
    titleString === title ? `%s | ${site.siteMetadata.title}` : "%s";
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={titleString}
      titleTemplate={titleTemplate}
      meta={getMetadataTags()}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
};
SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};
export default SEO;
