import Typography from "typography";
import oceanBeachTheme from "typography-theme-ocean-beach";

const typography = new Typography({
  ...oceanBeachTheme,
  headerFontFamily: ["Roboto", ...(oceanBeachTheme.headerFontFamily ?? [])],
  overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => {
    const overrides = oceanBeachTheme.overrideStyles(
      { adjustFontSizeTo, scale, rhythm },
      options
    );
    return {
      ...overrides,
    };
  },
});

export default typography;
export const rhythm = typography.rhythm;
