import React from "react";
import { css } from "@emotion/react";
import { Link } from "gatsby";

import { rhythm } from "../utils/typography";

import resume from "../downloads/taylor-reis-resume.pdf";

export default function Header({ title }) {
  return (
    <header>
      <nav
        css={css`
          display: flex;
          justify-content: space-between;
          align-content: center;
          margin: ${rhythm(1.5)} 0 ${rhythm(0.5)};
        `}
      >
        <Link to={`/`}>
          <h3
            css={css`
              font-style: normal;
              margin: 0;
            `}
          >
            {title}
          </h3>
        </Link>
        <div>
          <Link
            to={`/about/`}
            css={css`
              font-style: normal;
              margin-right: 1rem;
            `}
          >
            About
          </Link>
          <a href={resume} download>
            Resume
          </a>
        </div>
      </nav>
    </header>
  );
}
